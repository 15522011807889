import { loadableReady } from '@loadable/component';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AnalyticsProvider } from '@johnlewispartnership/wtr-website-analytics';
import SiteBanner from 'components/SiteBanner';
import { PreloadedDataProvider } from 'contexts/PreloadedData';
import { getAnalytics } from 'analytics';
import getPreloadedData from 'utils/dataPreloader/getPreloadedData';

const renderApp = async () => {
  const rootElement = createRoot(document.getElementById('common-site-wide-banner-root')!);

  const preloadedData = getPreloadedData('site-wide-banner');

  rootElement.render(
    <BrowserRouter>
      <AnalyticsProvider
        // eslint-disable-next-line no-underscore-dangle
        initialEvents={window.__WTR_COMMON__['site-wide-banner']?.analytics}
        analytics={getAnalytics()}
      >
        <PreloadedDataProvider preloadedData={preloadedData}>
          <SiteBanner />
        </PreloadedDataProvider>
      </AnalyticsProvider>
    </BrowserRouter>,
  );
};

loadableReady(
  () => {
    renderApp();
  },
  {
    namespace: 'common-site-wide-banner',
  },
);
